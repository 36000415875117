
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IApiToken } from '../../entities/apiToken';
import { createApiToken, getApiTokens, revokeApiToken } from '@/services/steamcord';
import { validateTokenName } from '@/services/validation/createApiToken';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import Token from '@/components/dashboard/ApiToken.vue';

export default defineComponent({
  async beforeRouteEnter(from, to, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const tokens = await getApiTokens(currentOrg.id);
    next((vm) => {
      (vm as any).setTokens(tokens);
    });
  },
  components: {
    Token,
  },
  computed: {
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, {
      appLoading: 'loading',
    }),
  },
  data() {
    return {
      loading: false,
      copySuccess: false,
      createdToken: '',
      name: '',
      nameError: '',
      tokens: [] as IApiToken[],
    };
  },
  methods: {
    async copyCreatedToken(): Promise<void> {
      await window.navigator.clipboard.writeText(this.createdToken);

      this.copySuccess = true;
      setTimeout(() => {
        this.copySuccess = false;
      }, 1000);
    },
    async createToken(): Promise<void> {
      if (this.loading || !this.currentOrg) {
        return;
      }

      this.nameError = validateTokenName(this.name);
      if (this.nameError) {
        return;
      }

      this.loading = true;
      try {
        this.createdToken = await createApiToken(this.currentOrg.id, this.name);
        await this.getTokens();
        this.name = '';
      } catch (err) {
        //
      }

      this.loading = false;
    },
    async getTokens() {
      if (this.currentOrg) {
        this.tokens = await getApiTokens(this.currentOrg.id);
      }
    },
    async revokeToken(id: string): Promise<void> {
      if (this.currentOrg) {
        await revokeApiToken(this.currentOrg.id, id);
        await this.getTokens();
      }
    },
    async setTokens(tokens: IApiToken[]) {
      this.tokens = tokens;
    },
  },
  mounted() {
    this.appLoading = false;
  },
});
