
import { defineComponent } from 'vue';
import { formatDate } from '@/util/formatDate';

export default defineComponent({
  computed: {
    formattedDate() {
      return formatDate(this.createdDate);
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    createdDate: {
      type: Date,
      required: true,
    },
  },
});
